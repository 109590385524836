import { Suspense, useEffect } from 'react';
import loadable from '@loadable/component'
import { Route, Switch, useHistory } from 'react-router-dom';
import './App.css';
// import Footer from './components/Footer';
// import Header from './components/Header';
// import Layout from './components/Layout';
// import Home from './pages/Homepage';
// import Registeration from './pages/Registration';
// import AppRoutes from './routes/AppRoutes';
import { HOME, REGISTERATION } from './routes/routeNames';

const Footer = loadable(() => import('./components/Footer'))
const Header = loadable(() => import('./components/Header'))
const Layout = loadable(() => import('./components/Layout'))
const Home = loadable(() => import('./pages/Homepage'))
const Registeration = loadable(() => import('./pages/Registration'))
const AppRoutes = loadable(() => import('./routes/AppRoutes'))



function App() {
  const history = useHistory();
  // const { isLogged } = useSelector((state) => state.authReducer);
  // console.log(isLogged);

  useEffect(() => {
    window.location.pathname === '/' && history.push(HOME);
  }, [history]);

  return (
    <Suspense fallback="Loading...">
      <div className="App">
        {/*<Header />*/}
        <Layout>
          <Switch>
            <Route exact path={HOME} component={Home} />
            <Route exact path={REGISTERATION} component={Registeration} />
            <AppRoutes />
          </Switch>
        </Layout>
        <Footer />
      </div>
    </Suspense>
  );
}

export default App;
